<template>
  <div>
    <div class="row">
      <div class="col-8 mb-3">
        <div class="radio-group-container">
          <!-- Doesnt work right now need to solve adding parameters for real time lookup -->
          <a-radio-group class="radio-group" v-model="status" @change="changeTab">
            <a-radio-button value="open">
              Åpne saker
            </a-radio-button>
            <a-radio-button value="closed" >
              Ferdige saker
            </a-radio-button>
            <a-radio-button value="all">
              Alle saker
            </a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <div class="col-4">
        <a-button v-if="['helpdeskSettings'].some(r => $store.state.user.roles.indexOf(r) >= 0)"
          style="float: right;"
          @click="$router.push('/helpdesk/settings')"
          >
          <i class="fe fe-settings" />
        </a-button>
        <a-button style="float: right" @click="showNewTicket = !showNewTicket">
          <span v-if="!showNewTicket">Ny sak</span>
          <span v-else>Lukk</span>
        </a-button>
      </div>
    </div>
    <div v-if="showNewTicket" class="mt-3">
      <new-ticket @newTicketCreated="newTicketCreated"/>
    </div>
    <div class="row" v-else>
      <div class="col-12 col-md-3" v-if="isSidebarVisible">
        <div class="mb-4">
          <!--Doesnt work right now need to implement seaarch filter-->
          <a-input placeholder="Søk sak" @change="search" v-model="searchText">
            <a-icon slot="prefix" type="search" />
          </a-input>
        </div>
        <div :class="$style.dialogs">
          <vue-custom-scrollbar style="height: 100%">
            <a
              href="#"
              v-for="(item, index) in dialogs"
              :key="item.id"
              :class="[$style.item, index === activeIndex ? $style.current : '']"
              class="d-flex flex-nowrap align-items-center"
              v-on:click.stop.prevent="changeDialog(index)"
            >
              <div class="kit__utils__avatar kit__utils__avatar--size46 mr-3 flex-shrink-0">
                <img :src="item.avatar" :alt="item.name" />
              </div>
              <div :class="$style.info" class="flex-grow-1">
                <div class="text-uppercase font-size-12 text-truncate text-gray-6">{{item.position}}</div>
                <div class="text-dark font-size-18 font-weight-bold text-truncate">{{item.name}}</div>
              </div>
              <div v-if="item.unread" :class="$style.unread" class="flex-shrink-0 align-self-start">
                <div class="badge badge-success">{{item.unread}}</div>
              </div>
            </a>
          </vue-custom-scrollbar>
        </div>
      </div>
      <div class="col-12 col-md-9" v-if="showChat">
        <div class="card">
          <div class="card-header card-header-flex align-items-center">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0 mr-2 font-size-18">
                {{dialogs[activeIndex].name}}
                <span class="font-size-14 text-gray-6">({{dialogs[activeIndex].position}})</span>
              </h5>
            </div>
            <div>
              <a-tooltip placement="top" v-if="['helpdeskTools'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
                <template slot="title">
                  <span>Helpdesk verktøy</span>
                </template>
                <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2" @click="toggleSidebar" >
                  <i class="fe fe-settings" />
                </a>
              </a-tooltip>
            </div>
          </div>
          <div class="card-body">
            <div class="height-700">
              <!-- Need to fix scrolling outside -->
              <div class="message-container" ref="messageContainer">
                <div class="spacer"></div>
                <div class="messages">
                <div class="d-flex flex-column justify-content-end">
                  <div
                    v-for="(message, index) in dialogs[activeIndex].dialog"
                    :key="index"
                    :class="[$style.message, message.owner.id !== $store.state.user.id ? $style.answer : '']"
                  >
                    <div :class="$style.messageContent">
                      <div
                        class="text-gray-4 font-size-12 text-uppercase"
                      >{{message.owner.name}}, {{moment(message.time).fromNow()}}</div>
                      <div>{{message.content}}</div>
                    </div>
                    <div class="kit__utils__avatar" :class="$style.messageAvatar">
                      <img
                        :src="message.owner.avatar"
                        :alt="message.owner.name"
                      />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <!-- Is typing needs to be fixed. Array typing inside dialogs object with users that are typing -->
            <!-- <div class="pt-2 pb-2" v-if="dialogs[activeIndex].typing">{{dialogs[activeIndex].name}} is typing...</div> -->
            <div class="pt-2 pb-2" ></div>
            <div class="input-group mb-3">
              <a-input type="text" class="form-control" placeholder="Send melding..." v-model="newMessage" @pressEnter="() => sendDialog(activeIndex, newMessage)"/>
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click="sendDialog(activeIndex, newMessage)">
                  <i class="fe fe-send align-middle" ></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3" v-if="!isSidebarVisible && ['helpdeskTools'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
        <h4>Support verktøy</h4>
        <a-form-item label="Status">
          <a-select v-model="dialogs[activeIndex].status" style="width: 100%" @change="changeStatus">
            <a-select-option value="open">Åpen</a-select-option>
            <a-select-option value="closed">Ferdig</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Tilgang" >
          <div v-for="(access, i) in dialogs[activeIndex].access" :key="i">
            {{ (employees.find(e=> e.id === access) || {}).name || access }} <i class="fe fe-x-circle" @click="removeAccess(i)"/>
          </div>
          <a-select @change="addAccess" show-search :filter-option="filterOption"
            option-filter-prop="children" style="width: 200px;" placeholder="Legg til">
            <a-select-option :value="option.id" v-for="option in employees" :key="option.id">
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </div>
  </div>
</template>
<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
// import dialogs from './data.json'
import newTicket from '@/components/custom/forms/newTicket'
import { firestore } from '@/services/firebase'
import moment from 'moment'

export default {
  components: {
    vueCustomScrollbar,
    newTicket,
  },
  data: function () {
    const activeIndex = 0
    return {
      status: 'open',
      searchText: '',
      activeIndex,
      dialogs: [],
      filteredDialogs: [],
      showNewTicket: false,
      showChat: false,
      newMessage: '',
      isSidebarVisible: true,
      employees: [],
      helpdeskSettings: {
        categories: [],
      },
    }
  },
  watch: {
    dialogs: {
      handler: function (val) {
        if (val.length > 0) {
          // filtered dialogs equaal to array of indexes with all indexes of val
          this.filteredDialogs = val.map((item, index) => index)
          this.searchText = ''
        }
      },
      deep: true,
    },
  },
  methods: {
    search(e) {
      const value = e.target.value
      const filteredDialogs = this.dialogs.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1)
      // create array with indexes that are met inside filter
      this.filteredDialogs = this.dialogs.map((item, index) => (filteredDialogs.includes(item) ? index : null)).filter((item) => item !== null)
    },
    moment,
    sendDialog(index, message) {
      const dialog = this.dialogs[index]
      if (message === '') return
      const newMessage = {
        content: message,
        time: moment().format('YYYY-MM-DDTHH:mm:ss'),
        owner: {
          id: this.$store.state.user.id,
          name: this.$store.state.user.name,
          avatar: this.$store.state.user.avatar,
        },
      }
      firestore.collection('helpdeskTickets')
        .doc(dialog.id)
        .collection('dialog')
        .add(newMessage)
        .then(() => {
          this.newMessage = ''
        })
    },
    changeDialog: function (index) {
      this.showChat = true
      this.activeIndex = index
      this.getDialogs(this.dialogs[index].id, index)
    },
    changeTab() {
      this.getTickets()
    },
    newTicketCreated(ticketid) {
      this.$router.push({ path: '/helpdesk/chat', query: { ticketid } })
      this.showNewTicket = false
    },
    findTicket(ticketid) {
      // get index of tickets from dialogs array
      const index = this.dialogs.findIndex((ticket) => ticket.id === ticketid)
      if (index > -1) {
        this.changeDialog(index)
      }
    },
    changeStatus(e) {
      console.log('change status', e)
      const dialog = this.dialogs[this.activeIndex]
      firestore.collection('helpdeskTickets')
        .doc(dialog.id)
        .update({ status: e })
    },
    async getTickets() {
      const access = []
      if (this.$store.state.user.roles.includes('helpdeskTools')) await this.getHelpdeskSettings()
      if (this.$store.state.user.roles.includes('helpdeskAll')) {
        access.push('all')
      }
      this.helpdeskSettings.categories.forEach((category) => {
        console.log('category', category)
        if (!category.access) return
        if (category.access.includes(this.$store.state.user.id)) {
          access.push(category.id)
        }
      })
      access.push(this.$store.state.user.id)
      console.log('access', access)
      let ref = firestore.collection('helpdeskTickets')
        .where('access', 'array-contains-any', access)
      // access rights
      if (this.status !== 'all') {
        ref = ref.where('status', '==', this.status)
      }
      await ref
        .onSnapshot((querySnapshot) => {
          this.dialogs = []
          querySnapshot.forEach((doc) => {
            const category = this.helpdeskSettings.categories.find((c) => c.id === doc.data().category).name
            const ticket = {
              id: doc.id,
              name: doc.data().subject,
              position: `${category} - ${doc.data().sentByName}`,
              avatar: 'resources/images/avatars/avatar-2.png',
              dialog: [],
              typing: false,
              status: doc.data().status,
              access: doc.data().access,
              sentBy: doc.data().sentBy,
            }
            this.dialogs.push(ticket)
          })
        })
    },
    async getDialogs(ticketid, index) {
      firestore.collection('helpdeskTickets')
        .doc(ticketid)
        .collection('dialog')
        .orderBy('time', 'asc')
        .onSnapshot((snapshot) => {
          this.dialogs[index].dialog = []
          snapshot.forEach((doc) => {
            this.dialogs[index].dialog.push(doc.data())
          })
        })
    },
    async getEmployees() {
      if (this.employees.length > this.helpdeskSettings.categories.length) return
      await firestore.collection('users').where('status', '==', 'active').orderBy('fullName', 'asc')
        .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.employees.push({ id: doc.id, name: doc.data().fullName })
          })
        })
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible
      this.getEmployees()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    removeAccess(i) {
      // remove access[i] from ticket
      const access = this.dialogs[this.activeIndex].access
      access.splice(i, 1)
      firestore.collection('helpdeskTickets')
        .doc(this.dialogs[this.activeIndex].id)
        .update({ access })
    },
    addAccess(uid) {
      const access = this.dialogs[this.activeIndex].access
      access.push(uid)
      firestore.collection('helpdeskTickets')
        .doc(this.dialogs[this.activeIndex].id)
        .update({ access })
      return ''
    },
    async getHelpdeskSettings() {
      const settingsRef = firestore.collection('settings').doc('helpdesk')
      await settingsRef.get()
        .then((doc) => {
          this.helpdeskSettings = doc.data()
          this.helpdeskSettings.categories = []
          // get categories from subcollection categories
        })
      await settingsRef.collection('categories').get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.helpdeskSettings.categories.push({
              ...doc.data(),
              id: doc.id,
            })
            this.employees.push({ id: doc.id, name: doc.data().name })
          })
        })
    },
  },
  updated() {
    if (!this.showChat) return
    if (this.showNewTicket) return
    this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight
  },
  mounted() {
    this.getTickets()
    const ticketid = this.$route.query.ticketid
    if (ticketid) this.findTicket(ticketid)
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>

<style>
.message-container {
  display: flex;
  flex-direction: column;
  height: 700px;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.spacer {
  flex-grow: 1;
}

.messages {
  flex-shrink: 0;
}

</style>
